<template>
  <b-card>
    <validation-observer ref="updateApp" #default="{ invalid }">
      <b-form @submit.prevent="updateApp(invalid)">
        <b-container>
          <b-row class="justify-content-md-center">
            <b-col cols="12" md="2">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                to="/myprofile/apps"
                v-b-tooltip.hover
                title="Go Back"
              >
                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
              </b-button>
            </b-col>
            <b-col cols="12" md="10">
              <h1>Edit the app</h1>
            </b-col>
          </b-row>
          <hr />
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <b-form-group label="App Name" label-for="app-name">
                <validation-provider
                  #default="{ errors }"
                  name="App Name"
                  rules="required"
                >
                  <b-form-input
                    id="app-name"
                    v-model="title"
                    readonly
                    placeholder="App Name"
                    :state="errors.length > 0 ? false : null"
                    autofocus
                  />
                  <small class="text-danger title_error">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- 
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <b-form-group label="App ID" label-for="app-id">
                <validation-provider
                  #default="{ errors }"
                  name="App ID"
                  rules="required"
                >
                  <b-form-input
                    id="app-id"
                    v-model="appId"
                    placeholder="App ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          -->
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <b-form-group label="Account" label-for="account">
                <validation-provider
                  #default="{ errors }"
                  name="Account"
                  rules="required"
                >
                  <v-select
                    v-model="account"
                    :options="accounts"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Account"
                    label="text"
                  />
                  <small class="text-danger account_name_error">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-col offset-md="3">
              <b-button
                type="submit"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-warning"
                class="mr-1"
                :disabled="invalid"
              >
                Update
              </b-button>
              <!--
                <b-button
                  type="reset"
                  ref="resetButton"
                  variant="outline-secondary"
                >
                  Reset
                </b-button>
              -->
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import { BFormSelect } from "bootstrap-vue";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      title: null,
      appId: null,
      account: null,
      accounts: [],
      data: {},
      // validation rules
      required,
      regex,
      url,
    };
  },
  computed: {
    appId() {
      return this.$store.state.appsManager.appID;
    },
  },
  created() {
    this.getAllAccounts();
    this.appendApp();
  },
  methods: {
    getAllAccounts() {
      this.$store
        .dispatch("appsManager/getAllAccounts")
        .then(({ data }) => {
          data.accounts.forEach((account) => {
            this.accounts.push({
              text: account.account_name,
              value: account.id,
            });
          });
        })
        .catch(console.error);
    },
    appendApp() {
      axios
        .get(`get-app/${this.appId}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.title = response.data.app.title;
            this.appId = response.data.app.appId;
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              response.data.message
            );
          }
        })
        .catch((exception) => {
          console.error(exception);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async updateApp(invalid) {
      await axios
        .post("/updateApp", {
          appId: this.appId,
          account_id: this.account ? this.account.value : 0,
        })
        .then(({ data }) => {
          if (res.data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            router.push({ name: "apps" });
            return;
            // this.$refs.resetButton.click();
          } else if (data.status == "failed") {
            for (const key in data.errors) {
              if (key !== "appId")
                document.querySelector(`.${key}_error`).textContent =
                  data.errors[key];
            }

            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
